export default function () {
  const { $sitewideConfig } = useNuxtApp()
  const headerNav = ref<HTMLElement | null>(null)
  const navBounding = useElementBounding(headerNav)

  onMounted(() => {
    headerNav.value = document.getElementById('stickyNavbar')
  })

  // This is for css offsets not scrollTo offset
  const headerOffset = computed(() => {
    if (!$sitewideConfig.header?.stickyEnabled) return 0
    return navBounding.bottom.value
  })

  return {
    headerOffset,
  }
}
